<template>
  <div>
    <v-card class="elevation-12">
      <v-toolbar color="info" dark flat>
        <v-toolbar-title>
          <span>{{ $i18n.translate("Registration") }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on: on }">
            <v-btn icon v-on="on" :to="{ name: 'login' }">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.translate("Cancel") }}</span>
        </v-tooltip>
      </v-toolbar>

      <ApiError :errors="errors" />

      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            {{ $i18n.translate("Account Info") }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="e1 > 2" step="2">
            {{ $i18n.translate("Personal Info") }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="e1 > 3" step="3">
            {{ $i18n.translate("Company Info") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="e1 > 4" step="4">
            {{ $i18n.translate("Distributors") }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="e1 > 4" :step="5">
            {{ $i18n.translate("Finish") }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form v-model="valid1" @submit.stop.prevent="onSubmit" auto-complete="no">
              <v-container>
                <v-row no-gutters>
                  <v-col class="text-center">
                    <p>
                      Do you have an existing Sales Contract with Sony?
                    </p>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col offset="5">
                    <v-radio-group v-model="q1">
                      <v-radio label="No" value="no" />
                      <v-radio label="Yes" value="yes" />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col offset-lg="2" lg="8" class="text-center">
                    <v-alert v-if="q1 == 'yes'" border="top" colored-border color="red" type="info" elevation="2">
                      If you have an existing Sales Contract with Sony, registration is not required. Simply
                      <router-link :to="{ name: 'login' }">login</router-link>,
                      <router-link :to="{ name: 'forgot-password' }">reset your credentials</router-link>, or call
                      {{ selectedProgram.programPhone }} for assistance.
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col class="text-center">
                    <p v-if="q1 == 'no'">
                      What is your role at the company?
                    </p>
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="q1 == 'no'">
                  <v-col offset="5" cols="4">
                    <v-select
                      :items="['Principal Owner', 'Company Admin', 'Company Sales Engineer or Sales Representative']"
                      label="Select Role"
                      v-model="q2"
                    />
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="q1 == 'no' && q2 == 'Company Admin'">
                  <v-col offset-lg="2" lg="8" class="text-center">
                    <v-alert border="top" colored-border type="info" color="red" elevation="2">
                      You must be a Principal Owner to register a company. For enrollment information, please contact
                      the Principal Owner at your location, or call {{ selectedProgram.programPhone }} for assistance.
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="q1 == 'no' && q2 == 'Company Sales Engineer or Sales Representative'">
                  <v-col offset-lg="2" lg="8" class="text-center">
                    <v-alert border="top" colored-border type="info" color="red" elevation="2">
                      You must be a Principal Owner to register a company. For information on all Sony claiming
                      opportunities, please contact the Principal Owner at your location, or call
                      {{ selectedProgram.programPhone }} for assistance.
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="q1 == 'no' && q2 == 'Principal Owner'">
                  <v-col offset-lg="2" lg="8" class="text-center">
                    Please enter the 9-digit Federal Tax Identification Number of your reseller location to determine if
                    already registered.
                  </v-col>
                </v-row>

                <v-row v-if="q1 == 'no' && q2 == 'Principal Owner'">
                  <v-col offset-lg="3" lg="6">
                    <CountryField label="Country" v-model="selectedCountry" v-if="program" :program="program" />
                  </v-col>
                </v-row>

                <v-row v-if="q1 == 'no' && q2 == 'Principal Owner'">
                  <v-col offset-lg="3" lg="6">
                    <FeinField
                      name="tin"
                      v-model="form.organization.tin"
                      :rules="rules.tin"
                      :error-messages="$error.getValidationError(errors, 'tin')"
                      @input="$error.clearValidationError(errors, 'tin')"
                      :country="selectedCountry"
                      :disabled="!selectedCountry"
                    />
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="existingOrganization">
                  <v-col offset-lg="2" lg="8" class="text-center">
                    <v-alert border="top" colored-border type="info" color="red" elevation="2">
                      It appears your location is already registered. Simply
                      <router-link :to="{ name: 'login' }"> login</router-link>,
                      <router-link :to="{ name: 'forgot-password' }">reset your credentials</router-link>, or call or
                      call {{ selectedProgram.programPhone }} for assistance.
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="text-center">
                    <v-btn
                      color="primary"
                      v-if="
                        q1 == 'no' &&
                          q2 == 'Principal Owner' &&
                          form.organization.tin.length == 10 &&
                          !existingOrganization
                      "
                      :disabled="!valid1"
                      @click="validateEintin()"
                    >
                      {{ $i18n.translate("Next") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form v-model="valid2" @submit.stop.prevent="onSubmit" autocomplete="off">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    name="firstName"
                    :label="`${$i18n.translate('Principal Owner')} ${$i18n.translate('First Name')}`"
                    id="firstName"
                    v-model="form.user.firstName"
                    :rules="rules.firstName"
                    :error-messages="$error.getValidationError(errors, 'firstName')"
                    @input="$error.clearValidationError(errors, 'firstName')"
                    autocomplete="off"
                    class="required"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    name="lastName"
                    :label="`${$i18n.translate('Principal Owner')} ${$i18n.translate('Last Name')}`"
                    id="lastName"
                    v-model="form.user.lastName"
                    :rules="rules.lastName"
                    :error-messages="$error.getValidationError(errors, 'lastName')"
                    @input="$error.clearValidationError(errors, 'lastName')"
                    autocomplete="off"
                    class="required"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <EmailField
                    name="email"
                    :label="this.$i18n.translate('Email')"
                    id="email"
                    v-model="form.user.email"
                    :rules="rules.email"
                    :error-messages="$error.getValidationError(errors, 'email')"
                    @input="$error.clearValidationError(errors, 'email')"
                    autocomplete="no"
                    class="required"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    name="address1"
                    :label="`${this.$i18n.translate('Address Line')} 1`"
                    id="address1"
                    v-model="form.participant.address.address1"
                    :rules="rules.address1"
                    :error-messages="$error.getValidationError(errors, 'address1')"
                    @input="$error.clearValidationError(errors, 'address1')"
                    autocomplete="no"
                    class="required"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    name="address2"
                    :label="`${this.$i18n.translate('Address Line')} 2`"
                    id="address2"
                    v-model="form.participant.address.address2"
                    :error-messages="$error.getValidationError(errors, 'address2')"
                    @input="$error.clearValidationError(errors, 'address2')"
                    autocomplete="no"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <CountryField
                    :label="this.$i18n.translate('Country')"
                    v-model="selectedCountry"
                    disabled
                    :available="selectedCountry ? [selectedCountry] : null"
                  />
                </v-col>
                <v-col cols="3">
                  <RegionField
                    :country="selectedCountry"
                    name="region"
                    id="region"
                    v-model="form.participant.address.region"
                    :rules="rules.region"
                    :error-messages="$error.getValidationError(errors, 'region')"
                    @input="$error.clearValidationError(errors, 'region')"
                    class="required"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    name="city"
                    :label="this.$i18n.translate('City')"
                    id="city"
                    v-model="form.participant.address.city"
                    :rules="rules.city"
                    :error-messages="$error.getValidationError(errors, 'city')"
                    @input="$error.clearValidationError(errors, 'city')"
                    autocomplete="no"
                    class="required"
                  />
                </v-col>
                <v-col cols="3">
                  <PostalCodeField
                    :country="selectedCountry"
                    v-model="form.participant.address.postalCode"
                    id="postalCode"
                    name="postalCode"
                    :rules="rules.postalCode"
                    class="required"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <PhoneNumberField
                    name="phoneNumber1"
                    :label="this.$i18n.translate('Phone Number')"
                    id="phoneNumber1"
                    v-model="form.participant.phoneNumber1"
                    :error-messages="$error.getValidationError(errors, 'phoneNumber1')"
                    @input="$error.clearValidationError(errors, 'phoneNumber1')"
                    :country="selectedCountry"
                    :rules="rules.phoneNumber"
                    class="required"
                  />
                </v-col>
              </v-row>
            </v-form>

            <v-row>
              <v-col>
                <v-btn color="secondary" @click="backToLogin()">
                  {{ $i18n.translate("Cancel") }}
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn color="primary" @click="e1--">
                  {{ $i18n.translate("Back") }}
                </v-btn>
                &nbsp;&nbsp;
                <v-btn color="primary" :disabled="!valid2" v-if="!existingOrganization" @click="e1++">
                  {{ $i18n.translate("Next") }}
                </v-btn>
                <v-btn color="primary" :disabled="!valid2" v-else @click="onSubmit()">
                  {{ $i18n.translate("Finish") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="existingOrganization ? 5 : 3">
            <v-form v-model="valid3" @submit.stop.prevent="onSubmit" auto-complete="no">
              <div v-if="!existingOrganization">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      name="name"
                      :label="this.$i18n.translate('Organization Name')"
                      id="name"
                      v-model="form.organization.name"
                      :rules="rules.name"
                      :error-messages="$error.getValidationError(errors, 'name')"
                      @input="$error.clearValidationError(errors, 'name')"
                      class="required"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      name="dba"
                      :label="this.$i18n.translate('Organization Dba')"
                      id="DBA"
                      v-model="form.organization.dba"
                      :error-messages="$error.getValidationError(errors, 'dba')"
                      @input="$error.clearValidationError(errors, 'dba')"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      name="address.address1"
                      :label="this.$i18n.translate('Organization') + ' ' + this.$i18n.translate('Address') + ' 1'"
                      id="address1"
                      v-model="form.organization.address.address1"
                      :rules="rules.address1"
                      :error-messages="$error.getValidationError(errors, 'address1')"
                      @input="$error.clearValidationError(errors, 'address1')"
                      class="required"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      name="address2"
                      :label="this.$i18n.translate('Organization') + ' ' + this.$i18n.translate('Address') + ' 2'"
                      id="address2"
                      v-model="form.organization.address.address2"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <CountryField
                      v-model="selectedCountry"
                      name="organizationCountry"
                      label="Company Country"
                      id="organizationCountry"
                      :available="selectedCountry ? [selectedCountry] : null"
                      disabled
                    />
                  </v-col>
                  <v-col cols="3">
                    <RegionField
                      :country="selectedCountry"
                      name="region"
                      v-model="form.organization.address.region"
                      id="region"
                      :rules="rules.region"
                      :error-messages="$error.getValidationError(errors, 'region')"
                      @input="$error.clearValidationError(errors, 'region')"
                      class="required"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      name="city"
                      :label="`${this.$i18n.translate('Organization')} ${this.$i18n.translate('City')}`"
                      id="city"
                      v-model="form.organization.address.city"
                      :rules="rules.city"
                      :error-messages="$error.getValidationError(errors, 'city')"
                      @input="$error.clearValidationError(errors, 'city')"
                      class="required"
                    />
                  </v-col>
                  <v-col cols="3">
                    <PostalCodeField
                      :country="selectedCountry"
                      v-model="form.organization.address.postalCode"
                      id="organizationPostalCode"
                      name="organizationPostalCode"
                      :rules="rules.postalCode"
                      class="required"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <PhoneNumberField
                      name="phoneNumber1"
                      :label="`${this.$i18n.translate('Organization')} ${this.$i18n.translate('Phone Number')}`"
                      id="phoneNumber1"
                      v-model="form.organization.phoneNumber1"
                      :error-messages="$error.getValidationError(errors, 'phoneNumber1')"
                      @input="$error.clearValidationError(errors, 'phoneNumber1')"
                      :country="selectedCountry"
                      :rules="rules.phoneNumber"
                      class="required"
                    />
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      name="website"
                      :label="`${this.$i18n.translate('Organization')} ${this.$i18n.translate('Website')}`"
                      id="website"
                      v-model="form.organization.website"
                      :rules="rules.website"
                      :error-messages="$error.getValidationError(errors, 'website')"
                      @input="$error.clearValidationError(errors, 'website')"
                      prefix="http://"
                      class="required"
                    />
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col>
                  <v-btn color="secondary" @click="backToLogin()">
                    Cancel
                  </v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn color="primary" @click="e1--">
                    Back
                  </v-btn>
                  &nbsp;&nbsp;
                  <v-btn color="primary" :disabled="!valid3" @click="e1++">
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-form v-model="valid4" @submit.stop.prevent="onSubmit" auto-complete="no">
              <v-row>
                <v-col cols="5">
                  <p>
                    Please tell us from which Distributor(s) you purchase Sony products. <br />
                    Multiple Distributors may be chosen. <br />
                    Your Distributor Account Number will help us confirm your registration request.
                  </p>
                  <CountryField
                    :label="this.$i18n.translate('Country')"
                    v-model="selectedCountry"
                    disabled
                    :available="selectedCountry ? [selectedCountry] : null"
                  />
                </v-col>
                <v-col cols="7">
                  <v-data-table
                    v-model="selectedDistributors"
                    :headers="distributorsHeaders"
                    :items="distributors[selectedCountry.name]"
                    v-if="distributors && selectedCountry && selectedCountry.name"
                    item-key="id"
                    show-select
                    hide-default-footer
                    class="elevation-1"
                    @item-selected="
                      if (!$event.value) {
                        $event.item.accountNumber = '';
                      }
                    "
                  >
                    <template v-slot:item.accountNumber="{ item }">
                      <v-text-field
                        v-model="item.accountNumber"
                        :disabled="selectedDistributors.indexOf(item) == -1"
                        :rules="[() => validateDistributor(item) || item.errorMessage]"
                      />
                    </template>
                  </v-data-table>
                  <br />
                  {{ distributorsError }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-right">
                  <v-btn color="primary" @click="e1 = e1 - 1"> {{ this.$i18n.translate("Back") }} </v-btn>&nbsp;&nbsp;
                  <v-btn
                    @click="onSubmit()"
                    :disabled="!(valid4 && validateDistributors())"
                    class="primary"
                    :loading="isLoading"
                  >
                    {{ this.$i18n.translate("Submit") }}
                  </v-btn>
                  <br />
                  <GoogleReCaptcha />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content :step="existingOrganization ? 3 : 5">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>
                      {{ finalTitle }}
                    </v-card-title>
                    <v-card-text v-html="finalDescription" />
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <v-btn color="primary" @click="e1 -= 1" v-if="!isSuccess">
              {{ $i18n.translate("Back") }}
            </v-btn>
            <v-row>
              <v-col class="text-right">
                <v-btn color="primary" @click="backToLogin()" v-if="isSuccess">
                  {{ $i18n.translate("Finish") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </div>
</template>

<script>
import PhoneNumberField from "@/gapp-components/components/fields/PhoneNumberField.vue";
import PostalCodeField from "@/gapp-components/components/fields/PostalCodeField.vue";
import RegionField from "@/gapp-components/components/fields/RegionField.vue";
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import FeinField from "@/gapp-components/components/fields/FeinField.vue";
import CountryField from "@/gapp-components/components/fields/CountryField.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import GoogleReCaptcha from "@/gapp-components/components/display/GoogleReCaptcha.vue";
import ValidationService from "@/gapp-components/services/validation.service.js";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  components: {
    GoogleReCaptcha,
    ApiError,
    CountryField,
    FeinField,
    EmailField,
    RegionField,
    PostalCodeField,
    PhoneNumberField
  },
  name: "Register",
  metaInfo: {
    title: "Register"
  },
  data() {
    return {
      valid1: false,
      valid2: false,
      valid3: false,
      valid4: false,
      validDistributors: false,
      isLoading: false,
      isSuccess: false,
      buttonText: "Next",
      errors: {},
      errorMessage: null,
      rules: {
        firstName: ValidationService.generateTextValidationRules("First Name"),
        lastName: ValidationService.generateTextValidationRules("Last Name"),
        tin: ValidationService.generateTextValidationRules("Tax Identification Number"),
        address1: ValidationService.generateTextValidationRules("Address 1"),
        country: ValidationService.generateTextValidationRules("Country"),
        region: [v => (v.name ? true : "Region is required.")],
        city: ValidationService.generateTextValidationRules("City"),
        postalCode: ValidationService.generateTextValidationRules("Postal Code"),
        phoneNumber: ValidationService.generateTextValidationRules("Phone Number"),
        distributors: ValidationService.generateTextValidationRules("Distributor"),
        name: ValidationService.generateTextValidationRules("Company Name"),
        email: ValidationService.generateEmailValidationRules("E-mail"),
        dba: ValidationService.generateEmailValidationRules("Dba", 100),
        website: ValidationService.generateEmailValidationRules("Website")
      },
      selectedCountry: null,
      form: {
        programKey: process.env.VUE_APP_PROGRAM_CPR_KEY,
        clientKey: process.env.VUE_APP_CLIENT_KEY,
        participantStatus: {},
        participantType: {
          participantTypeKey: 800
        },
        user: {
          firstName: "",
          lastName: "",
          email: "",
          username: ""
        },
        participant: {
          email1: "",
          participantType: {
            participantTypeKey: "800"
          },
          phoneNumber1: "",
          address: {
            address1: "",
            address2: "",
            country: {},
            region: {},
            city: "",
            postalCode: ""
          },
          participantStatus: { name: "PRE" }
        },
        organization: {
          name: "",
          DBA: "",
          address: {
            address1: "",
            address2: "",
            country: {},
            region: {},
            city: "",
            postalCode: ""
          },
          tin: "",
          organizationType: {
            organizationTypeKey: "INDIRECT_3PTY_RESELLER"
          },
          website: "",
          phoneNumber1: ""
        },
        relationships: []
      },
      distributorForm: {
        country: ""
      },
      e1: 1,
      selectedDistributors: [],
      distributors: {
        CAN: [
          {
            id: "630004387",
            name: "Synnex",
            regexValidation: /^1(\d){6}$/,
            errorMessage: "Should begin with 1 and have 7 digits"
          }
        ],
        USA: [
          {
            id: "630011196",
            name: "Almo",
            regexValidation: /^(\d){6}[A-Z]{1}$/,
            errorMessage: "Should have 6 digits and end with a Letter"
          },
          {
            id: "1002684",
            name: "Ademco(ADI)",
            regexValidation: /^[a-zA-Z]*[0-9]+$/,
            errorMessage: "Can be both numeric or a mix of Alpha plus Numeric"
          },
          {
            id: "1023328",
            name: "TD Synnex"
          }
        ]
      },
      distributorsId: 0,
      finalTitle: "",
      finalDescription: "",
      program: null,
      distributorsError: "",
      distributorsHeaders: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Account Number",
          value: "accountNumber"
        }
      ],
      existingOrganization: false,
      q1: "",
      q2: ""
    };
  },

  mounted() {
    // clear existing errors
    this.$store.dispatch("logout");

    // Fetch program
    this.$api.get(`/api/programs/byKey/${process.env.VUE_APP_PROGRAM_CPR_KEY}/public`).then(({ data }) => {
      this.program = data;
    });
  },
  watch: {
    selectedCountry(newVal, oldVal) {
      this.distributorForm.country = newVal;
      this.form.participant.address.country = newVal;
      this.form.organization.address.country = newVal;

      if (newVal !== oldVal) {
        this.resetParticipantAddressFields();
        this.resetOrganizationAddressFields();
      }
    }
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  },
  methods: {
    clearErrors() {
      this.existingOrganization = false;
      this.errors = {};
    },
    validateEintin() {
      this.$api
        .get(`/api/organizations/byTin/${this.form.organization.tin}/public`)
        .then(() => {
          this.existingOrganization = true;
          let apiError = this.$api.createError("Organization already exists");
          apiError.addValidationError("tin", "Already existing organization");
          this.errors = apiError;
        })
        .catch(() => {
          this.existingOrganization = false;
          this.e1++;
        });
    },
    validateDistributor(v) {
      if (v.accountNumber) {
        return v.regexValidation.test(v.accountNumber);
      } else {
        return true;
      }
    },
    validateDistributors() {
      if (this.selectedDistributors.length == 0) {
        this.distributorsError = "";
        return false;
      } else {
        let flag = false;
        for (let i = 0; i < this.selectedDistributors.length; i++) {
          if (!this.selectedDistributors[i].accountNumber && this.selectedDistributors[i].name !== "TD Synnex") {
            flag = true;
          }
        }
        if (flag) {
          this.distributorsError = "All selected distributors should have an account number.";
          return false;
        } else {
          this.distributorsError = "";
        }
      }

      this.distributorsError = "";
      return true;
    },

    async onSubmit() {
      // Set loading spinner
      this.isLoading = true;
      this.errors = {};
      this.errorMessage = null;
      Vue.set(this, "errors", []);

      // clear existing errors
      this.$store.dispatch("logout");

      // Preparing the form
      const postForm = {
        programKey: this.form.programKey,
        clientKey: this.form.clientKey,
        participant: {
          ...this.form.participant,
          user: {
            ...this.form.user,
            username: this.form.user.email
          },
          email1: this.form.user.email,
          participantType: { participantTypeKey: "800" },
          organization: {
            ...this.form.organization,
            organizationGroups: [{ organizationGroupKey: "IN" }]
          }
        }
      };

      // Adjusting addresses
      if (postForm.participant.address) {
        if (postForm.participant.address.region) {
          postForm.participant.address.region = { name: postForm.participant.address.region.name };
        }
        if (postForm.participant.address.country) {
          postForm.participant.address.country = { name: postForm.participant.address.country.name };
        }
      }

      if (postForm.participant.organization.address) {
        if (postForm.participant.organization.address.region) {
          postForm.participant.organization.address.region = {
            name: postForm.participant.organization.address.region.name
          };
        }
        if (postForm.participant.organization.address.country) {
          postForm.participant.organization.address.country = {
            name: postForm.participant.organization.address.country.name
          };
        }
      }

      if (!this.existingOrganization) {
        postForm.organizationRelatedOrganizations = this.selectedDistributors.map(distributor => ({
          relatedOrganization: { organizationKey: distributor.id },
          accountNumber: distributor.accountNumber,
          description: "distribution provided by"
        }));
      }

      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "REGISTER_WEBSITE_PRINCIPAL_INDIRECT"
      };

      // Sending the request
      try {
        await this.$api.postWithCaptcha("/api/participants/enroll/public", postForm, null, "register");
        this.e1 = 5;
        this.finalTitle = this.$i18n.translate("Registration completed!");
        this.finalDescription =
          "Thank you for registering your company on the Sony Professional Channel Partner Rebate Portal.<br><br>Your request has been forwarded to your Distributor Account Manager and is currently pending approval.<br><br>You will receive an email with additional details once your account has been reviewed. <br><br>";
        this.isSuccess = true;
      } catch (error) {
        console.error(error);
        this.e1 = 5;
        this.finalTitle = this.$i18n.translate("Registration error.");
        error.response.data.forEach(e => this.errors.push(e));
        this.finalDescription = this.errors && this.errors.message ? this.errors.message : "";
        this.isSuccess = false;
      } finally {
        this.isLoading = false;
      }
    },
    backToLogin() {
      this.$router.push({ name: "login" });
    },
    resetParticipantAddressFields() {
      this.form.participant.address.address1 = "";
      this.form.participant.address.address2 = "";
      this.form.participant.address.region = {};
      this.form.participant.address.city = "";
      this.form.participant.address.postalCode = "";
    },
    resetOrganizationAddressFields() {
      this.form.organization.address.address1 = "";
      this.form.organization.address.address2 = "";
      this.form.organization.address.region = {};
      this.form.organization.address.city = "";
      this.form.organization.address.postalCode = "";
    }
  }
};
</script>

<style>
.grecaptcha-badge {
  bottom: 40px !important;
}
</style>
